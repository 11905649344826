import { useContext } from "react";
import { Autocomplete, AutocompleteItem, Avatar } from "@nextui-org/react";
import { DataContext } from "../../../contexts/DataContext";
import { Opportunity } from "feathers-backend";
import { client } from "../../../utils/Client";

type SelectCustomerForOpportunityProps = {
  opportunity?: Opportunity;
};

export default function SelectCustomerForOpportunity({ opportunity }: SelectCustomerForOpportunityProps) {
  const { customers } = useContext(DataContext);

  // Create a new array of customers sorted by name also only make sure the array contains customer id and name
  const sortedCustomers = customers
    ?.filter((customer) => customer.name !== null)
    .map((customer) => ({ _id: customer._id, name: customer.name }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const onSelectionChange = (key: React.Key | null) => {
    if (opportunity) {
      opportunity.customerId = key;
      client.service("opportunities").patch(opportunity._id.toString(), { customerId: key });
    }
  };

  const onClear = () => {
    if (opportunity) {
      opportunity.customerId = "";
      client.service("opportunities").patch(opportunity._id.toString(), { customerId: null });
    }
  };

  return (
    <div className="flex flex-wrap gap-4 md:flex-nowrap">
      {customers && (
        <Autocomplete
          menuTrigger="focus"
          defaultItems={sortedCustomers}
          label="Customer"
          size="sm"
          fullWidth={true}
          placeholder="Select a customer"
          labelPlacement="inside"
          isClearable={true}
          selectedKey={opportunity?.customerId?.toString()}
          onSelectionChange={onSelectionChange}
          onClear={onClear}
        >
          {(customer) => (
            <AutocompleteItem key={customer._id.toString()} textValue={customer.name}>
              <div className="flex items-center gap-2">
                <div className="flex flex-col">
                  <span className="text-small">{customer.name}</span>
                </div>
              </div>
            </AutocompleteItem>
          )}
        </Autocomplete>
      )}
    </div>
  );
}
