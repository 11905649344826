import { useContext, useState } from "react";
import { Select, SelectItem } from "@nextui-org/react";
import { DataContext } from "../../../contexts/DataContext";
import UserAvatar from "../../UserAvatar";
import { User } from "feathers-backend";
import { UserContext } from "../../../contexts/UserContext";

type Props = {
  name: string;
  size?: "sm" | "md" | "lg";
  onChange: (name: string, value: string | null) => void;
  value: string | undefined; // Assuming 'undefined' can be an initial state or when cleared
};

export default function SelectAssignee({ name, onChange, value, size }: Props) {
  const { users } = useContext(DataContext);
  const { lang } = useContext(UserContext);
  const [sortedUsers, setSortedUsers] = useState(new Set(users));

  const handleChange = (value: string | null) => {
    onChange(name, value);
  };

  return (
    <Select
      aria-label={"Select a user"}
      items={sortedUsers}
      size={size || "lg"}
      classNames={{ value: "text-sm" }}
      fullWidth
      defaultSelectedKeys={value ? [value] : []}
      placeholder={lang("Select a user")}
      onChange={(e) => handleChange(e.target.value)}
      renderValue={(items) => {
        return items.map((item) => (
          <div key={item.data?._id.toString()} className="flex items-center gap-2">
            <UserAvatar user={item.data as User} size="sm" />
            <div className="flex flex-col">
              <span className="text-small">{item.data?.fullName}</span>
              <span className="text-tiny text-default-400">{item.data?.email}</span>
            </div>
          </div>
        ));
      }}
    >
      {(user) => (
        <SelectItem key={user._id.toString()} textValue={user.fullName}>
          <div className="flex items-center gap-2">
            <UserAvatar user={user} size="sm" />
            <div className="flex flex-col">
              <span className="text-small">{user.fullName}</span>
              <span className="text-tiny text-default-400">{user.email}</span>
            </div>
          </div>
        </SelectItem>
      )}
    </Select>
  );
}
