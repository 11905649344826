import {
  CartesianGrid,
  BarChart as BarChartComponent,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Bar,
  Legend,
  Tooltip,
} from "recharts";
import { DataContext } from "../../contexts/DataContext";
import { useContext, useEffect, useState } from "react";
import getColor, { getChannelColor } from "../../utils/GetColor";

type MonthlyData = {
  name: string;
  [channel: string]: string | number;
};

export default function BarChart() {
  const { opportunities } = useContext(DataContext);
  const [data, setData] = useState<MonthlyData[]>([]);
  const [uniqueKeys, setUniqueKeys] = useState<any>();

  const getMonthName = (timestamp: number) => {
    return new Date(timestamp).toLocaleString("default", { month: "short" });
  };

  const getMonthlyData = () => {
    const today = new Date();
    const data = [];

    for (let i = 0; i < 12; i++) {
      const monthStart = new Date(today.getFullYear(), today.getMonth() - i, 1).getTime();
      const monthEnd = new Date(today.getFullYear(), today.getMonth() - i + 1, 1).getTime();

      const monthlyOpportunities = opportunities.filter(
        (opp) => (opp.createdAt || 0) >= monthStart && (opp.createdAt || 0) < monthEnd
      );

      const channels: { [key: string]: number } = {};
      monthlyOpportunities.forEach((opp) => {
        if (opp.channel) {
          channels[opp.channel] = (channels[opp.channel] || 0) + 1;
        }
      });

      const monthData = {
        name: getMonthName(monthStart),
        ...channels,
      };

      data.push(monthData);
    }

    // Reverse the array to get the oldest month first
    return data.reverse();
  };

  useEffect(() => {
    setData(getMonthlyData());
  }, [opportunities]);

  useEffect(() => {
    const allKeys: any[] = [];
    data.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (key !== "name" && allKeys.indexOf(key) === -1) {
          allKeys.push(key);
        }
      });
    });
    setUniqueKeys(allKeys);
  }, [data]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChartComponent
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis stroke="#D9DBE9" dataKey="name" />
        <CartesianGrid vertical={false} stroke="#262338" strokeDasharray="3 3" />
        <Legend />
        <Tooltip cursor={false} contentStyle={{ border: "none", backgroundColor: "#0e0e1c" }} />

        {uniqueKeys &&
          uniqueKeys.map((key: any, index: number) => (
            <Bar
              key={key}
              dataKey={key}
              radius={[15, 15, 15, 15]}
              barSize={12}
              style={{ stroke: "#0e0e1c", strokeWidth: 4 }}
              stackId="a"
              fill={getChannelColor(key)}
            />
          ))}
      </BarChartComponent>
    </ResponsiveContainer>
  );
}
