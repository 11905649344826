import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { client } from "../utils/Client";
import { User } from "feathers-backend";

export const AuthContext = createContext({
  me: {} as User,
  updateAvatar: (newAvatarPath: string) => {},
});

export function AuthProvider(props: any) {
  const navigate = useNavigate();
  const [authMessage, setAuthMessage] = useState("");
  const [me, setMe] = useState<User>({} as User);

  useEffect(() => {
    const reauthenticate = async () => {
      try {
        await client
          .reAuthenticate()
          .then((res) => {
            setMe(res.user);
          })
          .catch(() => {
            navigate("/login");
          });
      } catch (error: any) {
        navigate("/login");
      }
    };

    // Run reauthenticate immediately when component mounts
    reauthenticate();

    // Set interval to run reauthenticate every 5 minutes
    const interval = setInterval(reauthenticate, 300000); // 300000 milliseconds = 5 minutes

    // Clear interval when component unmounts
    return () => clearInterval(interval);
  }, [navigate]);

  const updateAvatar = async (newAvatarPath: string) => {
    setMe((prev) => ({ ...prev, avatar: newAvatarPath + "?" + Date.now() }));
  };

  const logout = async () => {
    await client.logout();
    navigate("/login");
  };

  const value = {
    me,
    logout,
    updateAvatar,
  };

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
}
