import { DevicePhoneMobileIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { Input, Textarea } from "@nextui-org/react";
import { Contact } from "feathers-backend";
import React from "react";

type ContactFormProps = {
  values: Contact;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: any) => void;
};

export default function ContactForm({ values, handleChange, handleBlur }: ContactFormProps) {
  return (
    <div>
      <div className="grid grid-cols-2 gap-2">
        <Input
          label="First name"
          name="contact.firstName"
          type="text"
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label="Last name"
          name="contact.lastName"
          type="text"
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label="Email"
          name="contact.email"
          type="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          startContent={
            <EnvelopeIcon className="flex-shrink-0 w-4 h-4 text-2xl pointer-events-none text-default-400" />
          }
        />
        <Input
          label="Phone"
          name="contact.phone"
          type="text"
          value={values.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          startContent={
            <DevicePhoneMobileIcon className="flex-shrink-0 w-4 h-4 text-2xl pointer-events-none text-default-400" />
          }
        />
        <Input
          label="Title"
          name="contact.title"
          type="text"
          value={values.title}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <div className="col-span-2">
          <Textarea
            label="Notes"
            name="contact.notes"
            type="text"
            value={values.notes}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
    </div>
  );
}
