import {
  BellAlertIcon,
  BellSnoozeIcon,
  CheckIcon,
  ExclamationCircleIcon,
  ShieldExclamationIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Avatar as AvatarComponent, Badge, Image } from "@nextui-org/react";
import React from "react";

// Define a specific type for the status values
type StatusType = "unassigned" | "followup" | "closedWon" | "closedLost" | "inProgress";

type AvatarProps = {
  status?: StatusType;
  image?: string;
  size?: "sm" | "md" | "lg";
  radius?: "sm" | "md" | "lg" | "none" | "full";
  name: string;
};

// Define the properties based on the specific status types
const statusProps: { [key in StatusType]?: { color: string; badgeContent?: JSX.Element; badgeColor?: string } } = {
  unassigned: { color: "primary" },
  followup: { color: "warning", badgeContent: <BellSnoozeIcon />, badgeColor: "warning" },
  closedWon: { color: "success", badgeContent: <CheckIcon className="w-3 h-3" />, badgeColor: "success" },
  closedLost: {
    color: "danger",
    badgeContent: <XMarkIcon className="w-3 h-3 text-pink-950" />,
    badgeColor: "danger",
  },
  inProgress: { color: "default" },
};

export default function Avatar({ status = "inProgress", image, size = "md", radius = "md", name }: AvatarProps) {
  const { color, badgeContent, badgeColor } = statusProps[status] || {};

  const avatar = (
    <AvatarComponent
      imgProps={{ loading: "eager" }}
      showFallback
      name={name}
      color={color as any}
      className="text-white bg-card"
      radius={radius}
      isBordered
      size={size}
      src={image}
    />
  );

  return (
    <>
      {badgeContent ? (
        <Badge isOneChar content={badgeContent} color={badgeColor as any} placement="bottom-right">
          {avatar}
        </Badge>
      ) : (
        avatar
      )}
    </>
  );
}
