import React, { useContext, useEffect } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  Button,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Pagination,
  Selection,
  SortDescriptor,
} from "@nextui-org/react";

import { capitalize } from "../../utils/Capitalize";
import { Contact, Customer, Opportunity, Prospect, User } from "feathers-backend";
import { ChevronDownIcon, MagnifyingGlassIcon, PlusIcon } from "@heroicons/react/24/outline";
import { UserContext } from "../../contexts/UserContext";
import Avatar from "../Avatar";
import moment from "moment";
import Tags from "./Tags";
import Formatter from "../../utils/Formatter";
import { apiUrl } from "../../utils/Client";
import { DataContext } from "../../contexts/DataContext";
import SelectAssignee from "../Forms/Fields/SelectAssignee";
import { AuthContext } from "../../contexts/AuthContext";
import TagsFilter from "../TagsFilter";

// Extend the User type with a tags array

type GenericTableProps = {
  columns: { uid: string; name: string; sortable?: boolean }[];
  data: Opportunity[] | Prospect[] | Customer[] | User[] | Contact[];
  statusOptions?: { uid: string; name: string }[];
  filterOnTags?: boolean;
  filterOnUser?: boolean;
  initialVisibleColumns: string[];
  defaultSort?: SortDescriptor;
  onRowClick?: (item: Opportunity | Prospect | Customer | User) => void;
  addFunction?: () => void;
};

export default function GenericTable({
  columns,
  data,
  statusOptions,
  filterOnTags,
  filterOnUser,
  initialVisibleColumns,
  defaultSort,
  onRowClick,
  addFunction,
}: GenericTableProps) {
  const { lang } = useContext(UserContext);
  const { users, tags, customers, tagsFilter } = useContext(DataContext);
  const [filterValue, setFilterValue] = React.useState("");
  const [visibleColumns, setVisibleColumns] = React.useState<Selection>(new Set(initialVisibleColumns));
  const [statusFilter, setStatusFilter] = React.useState<Selection>(
    new Set(statusOptions?.map((status) => status.uid))
  );

  const [usersFilter, setUsersFilter] = React.useState<string | undefined>(undefined);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sortDescriptor, setSortDescriptor] = React.useState<SortDescriptor>(
    defaultSort || {
      column: "age",
      direction: "ascending",
    }
  );

  function hasTags(item: any): item is { tags: string[] } {
    return item && Array.isArray(item.tags);
  }

  const [page, setPage] = React.useState(1);

  const hasSearchFilter = Boolean(filterValue);

  const headerColumns = React.useMemo(() => {
    if (visibleColumns === "all") return columns;

    return columns.filter((column) => Array.from(visibleColumns).includes(column.uid));
  }, [visibleColumns]);

  function hasStatusProperty(item: any): item is { status: string } {
    return "status" in item && typeof item.status === "string";
  }

  const filterByAssignedUser = (item: any) => {
    // First, check if the selection is 'null'
    if (usersFilter === undefined) {
      return true; // If 'null', no filtering on users
    }
    // If it's a Set, proceed with filtering
    if (usersFilter) {
      const isAssignedToUser = usersFilter === item.assignedTo;
      const isUnassigned = !item.assignedTo; // Adjust based on how unassigned users are represented in your data
      return isAssignedToUser || isUnassigned;
    }
    return true; // Default to showing all if the type is neither
  };

  const filteredItems = React.useMemo(() => {
    let filteredData = [...data];

    if (hasSearchFilter) {
      const filterParts = filterValue.toLowerCase().split(" ");
      filteredData = filteredData.filter((item) => {
        return filterParts.every((part) =>
          Object.values(item).some((value) => value !== null && value.toString().toLowerCase().includes(part))
        );
      });
    }
    // Apply status filtering only if statusOptions is provided and not equal to "all"
    if (statusOptions && statusOptions.length > 0) {
      filteredData = filteredData.filter(
        (item) => hasStatusProperty(item) && Array.from(statusFilter).includes(item.status)
      );
    }

    // Filter by tags if filterOnTags is true
    if (filterOnTags && tags.length > 0) {
      if (Array.from(tagsFilter).length > 0) {
        filteredData = filteredData.filter(
          (item) => hasTags(item) && item.tags.some((tagId) => Array.from(tagsFilter).includes(tagId.toString()))
        );
      }
    }

    // Apply user filter if filterOnUser is true
    if (filterOnUser && usersFilter) {
      filteredData = filteredData.filter(filterByAssignedUser);
    }

    return filteredData;
  }, [data, usersFilter, filterOnUser, filterValue, statusFilter, tagsFilter, statusOptions?.length]);

  const pages = Math.ceil(filteredItems.length / rowsPerPage);

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return filteredItems.slice(start, end);
  }, [page, filteredItems, rowsPerPage]);

  const sortedAndPagedItems = React.useMemo(() => {
    const sortedItems = [...filteredItems].sort((a: any, b: any) => {
      const first = a[sortDescriptor.column as keyof any] as number;
      const second = b[sortDescriptor.column as keyof any] as number;
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });

    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return sortedItems.slice(start, end);
  }, [page, filteredItems, rowsPerPage, sortDescriptor]);

  const renderCell = React.useCallback(
    (data: any, columnKey: React.Key) => {
      const cellValue = data[columnKey as keyof any];
      switch (columnKey) {
        case "customer":
          return (
            <div className="flex">
              <div className="my-auto ml-2 mr-4">
                <Avatar name={data.name} status={data.status} radius="md" size="md" image={data.avatar} />
              </div>
              <div className="flex flex-col w-full">
                <div className="text-white ">{data.name}</div>
                <div className="text-sm text-placehold">{data.website}</div>
              </div>
            </div>
          );

        case "user":
          return (
            <div className="flex">
              <div className="my-auto ml-2 mr-4">
                <Avatar
                  name={(data.firstName ?? "") + " " + (data.lastName ?? "")}
                  status={data.status}
                  radius="md"
                  size="md"
                  image={data.avatar}
                />
              </div>
              <div className="flex flex-col w-full">
                <div className="text-white ">
                  {data.firstName} {data.lastName}
                </div>
                <div className="text-sm text-placehold">{data.email}</div>
              </div>
            </div>
          );

        case "prospect":
          return (
            <div className="flex">
              <div className="my-auto ml-2 mr-4">
                <Avatar
                  name={(data.contact?.firstName ?? "") + " " + (data.contact?.lastName ?? "")}
                  status={data.status}
                  radius="md"
                  size="md"
                  image={data.avatar}
                />
              </div>
              <div className="flex flex-col w-full">
                <div className="text-white ">
                  {data.contact?.firstName} {data.contact?.lastName}
                </div>
                <div className="text-sm text-placehold">{data.customer?.name || data.contact?.email}</div>
              </div>
            </div>
          );

        case "contact":
          // find the customer based on customers.contacts
          const customer = customers.find((customer) => customer.contacts?.includes(data._id));
          return (
            <div className="flex">
              <div className="my-auto ml-2 mr-4">
                <Avatar
                  name={(data.firstName ?? "") + " " + (data.lastName ?? "")}
                  status={data.customer}
                  radius="md"
                  size="md"
                  image={data.avatar}
                />
              </div>
              <div className="flex flex-col w-full">
                <div className="text-white ">
                  {data.firstName} {data.lastName}
                </div>
                <div className="text-sm text-placehold">{customer?.name}</div>
              </div>
            </div>
          );

        case "deal":
          return (
            <div className="flex">
              <div className="my-auto ml-2 mr-4">
                <Avatar name={data.displayName} status={data.status} radius="md" size="md" image={data.avatar} />
              </div>
              <div className="flex flex-col w-full">
                <div className="text-white ">{data.displayName}</div>
                <div className="text-sm text-placehold">{data.companyName || data.contactEmail}</div>
              </div>
            </div>
          );
        case "closedDate":
          return data.closedDate && <div>{moment.unix(data.closedDate / 1000).format("YYYY-MM-DD hh:mm:ss")}</div>;
        case "createdAt":
          return data.createdAt && <div>{moment.unix(data.createdAt / 1000).format("YYYY-MM-DD hh:mm:ss")}</div>;
        case "lastLogin":
          return data.lastLogin && <div>{moment.unix(data.lastLogin / 1000).fromNow()}</div>;
        case "company":
          return (
            <div className="flex flex-col">
              <p className=" text-bold text-small">{data.companyName}</p>
              <p className=" text-bold text-tiny text-default-400">{data.companyWebsite}</p>
            </div>
          );
        case "closedBy":
          const closedBy = users.find((user) => user._id.toString() === data.closedBy);
          return (
            <>
              {closedBy && (
                <div className="flex items-center gap-2">
                  <Avatar name={closedBy?.firstName ?? ""} radius="sm" size="sm" image={apiUrl + closedBy?.avatar} />
                  <div className="flex flex-col">
                    <span>{closedBy?.fullName}</span>
                    <span className="text-default-400 text-tiny">{closedBy?.email}</span>
                  </div>
                </div>
              )}
            </>
          );
        case "assignedTo":
          const assignedTo = users.find((user) => user._id.toString() === data.assignedTo);
          return (
            <>
              {assignedTo && (
                <div className="flex items-center gap-2">
                  <Avatar
                    name={assignedTo?.firstName ?? ""}
                    radius="sm"
                    size="sm"
                    image={apiUrl + assignedTo?.avatar}
                  />
                  <div className="flex flex-col">
                    <span>{assignedTo?.fullName}</span>
                    <span className="text-default-400 text-tiny">{assignedTo?.email}</span>
                  </div>
                </div>
              )}
            </>
          );
        case "value":
          return <div>{data.value ? Formatter(data.value) : ""}</div>;
        case "tags":
          return <Tags tagsList={data.tags} />;
        default:
          return cellValue;
      }
    },
    [users]
  );

  const onNextPage = React.useCallback(() => {
    if (page < pages) {
      setPage(page + 1);
    }
  }, [page, pages]);

  const onPreviousPage = React.useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [page]);

  const onRowsPerPageChange = React.useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1);
  }, []);

  const onSearchChange = React.useCallback((value?: string) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  const onClear = React.useCallback(() => {
    setFilterValue("");
    setPage(1);
  }, []);

  const topContent = React.useMemo(() => {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex gap-3">
          <Input
            classNames={{
              inputWrapper: "py-0 h-5",
            }}
            className="w-full"
            isClearable
            placeholder={lang("Search")}
            startContent={<MagnifyingGlassIcon className="w-5 h-5" />}
            value={filterValue}
            onClear={() => onClear()}
            onValueChange={onSearchChange}
          />
          {filterOnUser && (
            <div className="max-w-[300px] w-full">
              <SelectAssignee
                size="md"
                name="selectUser"
                onChange={(name, value) => setUsersFilter(value ?? undefined)}
                value={usersFilter}
              />
            </div>
          )}

          {statusOptions && (
            <div>
              <Dropdown>
                <DropdownTrigger className="hidden sm:flex">
                  <Button endContent={<ChevronDownIcon className="w-3 h-3 text-small" />} variant="flat">
                    Status
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  disallowEmptySelection
                  aria-label="Table Columns"
                  closeOnSelect={false}
                  selectedKeys={statusFilter}
                  selectionMode="multiple"
                  onSelectionChange={setStatusFilter}
                >
                  {statusOptions.map((status) => (
                    <DropdownItem key={status.uid} className="capitalize">
                      {capitalize(status.name)}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          )}
          {filterOnTags && tags.length > 0 && (
            <div className="flex w-auto min-w-[200px] flex-shrink-0">
              <TagsFilter />
            </div>
          )}
          <div>
            <Dropdown>
              <DropdownTrigger className="hidden sm:flex">
                <Button endContent={<ChevronDownIcon className="w-3 h-3 text-small" />} variant="flat">
                  {lang("Columns")}
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                disallowEmptySelection
                aria-label="Table Columns"
                closeOnSelect={false}
                selectedKeys={visibleColumns}
                selectionMode="multiple"
                onSelectionChange={setVisibleColumns}
              >
                {columns.map((column) => (
                  <DropdownItem key={column.uid} className="capitalize">
                    {capitalize(column.name)}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
          {addFunction && (
            <div>
              <Button
                className="w-auto"
                onClick={() => {
                  addFunction();
                }}
                color="primary"
                endContent={<PlusIcon className="w-5 h-5 text-white" />}
              >
                {lang("Add")}
              </Button>
            </div>
          )}
        </div>
        <div className="flex items-center justify-between">
          <span className="text-default-400 text-small">
            {filteredItems.length} {lang("rows")}
          </span>
          <label className="flex items-center text-default-400 text-small">
            {lang("Rows per page")}
            <select
              className="w-10 ml-2 bg-transparent border-0 outline-none text-placehold text-small focus:ring-0 focus:border-0"
              onChange={onRowsPerPageChange}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </label>
        </div>
      </div>
    );
  }, [
    filterValue,
    statusFilter,
    tagsFilter,
    visibleColumns,
    onSearchChange,
    onRowsPerPageChange,
    data.length,
    hasSearchFilter,
  ]);

  const bottomContent = React.useMemo(() => {
    return (
      <div className="grid grid-cols-3">
        <div className=""></div>
        <Pagination
          className="mx-auto"
          isCompact
          showControls
          showShadow
          color="primary"
          page={page}
          total={pages}
          onChange={setPage}
        />
        <div className="justify-end hidden gap-2 sm:flex">
          <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onPreviousPage}>
            {lang("Previous")}
          </Button>
          <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onNextPage}>
            {lang("Next")}
          </Button>
        </div>
      </div>
    );
  }, [items.length, page, pages, hasSearchFilter]);

  return (
    <Table
      aria-label="Generic Table"
      isHeaderSticky
      bottomContent={bottomContent}
      bottomContentPlacement="outside"
      sortDescriptor={sortDescriptor}
      topContent={topContent}
      topContentPlacement="outside"
      onSortChange={setSortDescriptor}
    >
      <TableHeader columns={headerColumns}>
        {(column) => (
          <TableColumn
            key={column.uid}
            align={column.uid === "actions" ? "center" : "start"}
            allowsSorting={column.sortable}
          >
            {column.name}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody emptyContent={"No rows found"} items={sortedAndPagedItems}>
        {(item) => (
          <TableRow
            className="cursor-pointer hover:bg-opacity-30 hover:bg-ash"
            onClick={() => onRowClick?.(item as any)}
            key={item._id.toString()} // Adjust this line
          >
            {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
