import "./App.css";
import Layout from "./layout/Layout";
import { AuthProvider } from "./contexts/AuthContext";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import { UserProvider } from "./contexts/UserContext";
import { DataProvider } from "./contexts/DataContext";
import Board from "./pages/Board";
import { Modal, NextUIProvider } from "@nextui-org/react";
import Settings from "./pages/Settings/Settings";
import Profile from "./pages/Settings/Profile";
import Team from "./pages/Settings/Team";
import Integrations from "./pages/Settings/Integrations";
import Export from "./pages/Settings/Export";
import Faq from "./pages/Settings/Faq";
import Prospects from "./pages/Prospects/Prospects";
import Customfields from "./pages/Settings/Customfields";
import Deals from "./pages/Deals/Deals";
import { ModalProvider } from "./contexts/ModalContext";
import Customers from "./pages/Customers/Customers";
import CustomerShow from "./pages/Customers/CustomerShow";
import CustomerDetails from "./pages/Customers/CustomerDetails";
import CustomerContacts from "./pages/Customers/CustomerContacts";
import CustomerActivities from "./pages/Customers/CustomerActivities";
import CustomerDeals from "./pages/Customers/CustomerDeals";
import Signup from "./pages/Signup";
import Tags from "./pages/Settings/Tags";
import Rexor from "./pages/Settings/Integrations/Rexor";
import Oneflow from "./pages/Settings/Integrations/Oneflow";
import Links from "./pages/Settings/Links";
import Contacts from "./pages/Contacts/Contacts";

function App() {
  return (
    <NextUIProvider>
      <div>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup/:id" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            element={
              <AuthProvider>
                <UserProvider>
                  <DataProvider>
                    <ModalProvider>
                      <Layout />
                    </ModalProvider>
                  </DataProvider>
                </UserProvider>
              </AuthProvider>
            }
          >
            <Route path="/" element={<Dashboard />} />
            <Route path="/prospects" element={<Prospects />} />
            <Route path="/followups" element={<h1>Follow-up</h1>} />
            <Route path="/deals" element={<Deals />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/customers/:id" element={<CustomerShow />}>
              <Route path="details" element={<CustomerDetails />} />
              <Route path="contacts" element={<CustomerContacts />} />
              <Route path="activities" element={<CustomerActivities />} />
              <Route path="deals" element={<CustomerDeals />} />
            </Route>
            <Route path="/board/:id" element={<Board />} />
            <Route path="/settings" element={<Settings />}>
              <Route path="profile" element={<Profile />} />
              <Route path="team" element={<Team />} />
              <Route path="customfields" element={<Customfields />} />
              <Route path="tags" element={<Tags />} />
              <Route path="integrations" element={<Integrations />} />
              <Route path="links" element={<Links />} />
              <Route path="rexor" element={<Rexor />} />
              <Route path="oneflow" element={<Oneflow />} />
              <Route path="export" element={<Export />} />
              <Route path="faq" element={<Faq />} />
            </Route>
          </Route>
        </Routes>
      </div>
    </NextUIProvider>
  );
}

export default App;
