import { Board, Column } from "feathers-backend";
import Formatter from "../../utils/Formatter";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataContext";
import { ModalContext } from "../../contexts/ModalContext";
import KanBanColumnForm from "./KanBanColumnForm";
import { useCountUp } from "react-countup";
import CountUp from "react-countup";
import { AuthContext } from "../../contexts/AuthContext";

type KanbanColumnType = {
  column: Column;
  board: Board;
};

export default function KanbanColumn({ column }: KanbanColumnType) {
  const { openModal } = useContext(ModalContext);
  const { opportunities } = useContext(DataContext);
  const { me } = useContext(AuthContext);
  const [calculatedValue, setCalculatedValue] = useState(0);
  useEffect(() => {
    const calculateValue = () => {
      let value: number = 0;
      for (const item of column.items) {
        const opportunity = opportunities.find((opportunity) => opportunity._id.toString() === item._id);
        if (opportunity && opportunity.expectedValue) {
          value += Number(opportunity.expectedValue);
        }
      }
      setCalculatedValue(value);
    };
    calculateValue();
  }, [column, opportunities]);

  return (
    <>
      {column && (
        <div className="">
          <div
            onClick={() => me.role === "admin" && openModal(column.name, <KanBanColumnForm column={column} />)}
            className="flex flex-col w-full h-12 mx-auto mb-2 font-semibold text-center uppercase rounded-lg cursor-pointer text-foreground-500 bg-card hover:bg-ash text-tiny"
          >
            <div className="my-auto">
              <div className="">{column.name}</div>
              <span className="text-white">
                <CountUp separator=" " suffix=" kr" duration={0.5} end={calculatedValue} />
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
