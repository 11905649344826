import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import GenericTable from "../../components/Table/GenericTable";
import { DataContext } from "../../contexts/DataContext";
import { ModalContext } from "../../contexts/ModalContext";
import { useNavigate } from "react-router-dom";
import CustomerDetails from "./CustomerDetails";

export default function Customers() {
  const { lang } = useContext(UserContext);
  const { customers, users, contacts } = useContext(DataContext);
  const { openModal } = useContext(ModalContext);
  const navigate = useNavigate();

  return (
    <div className="flex flex-col space-y-10">
      <div className="flex items-center justify-between">
        <h1 className="ml-2 text-2xl font-light">{lang("Customers")} </h1>
      </div>
      <div className="flex">
        {customers && users && (
          <GenericTable
            data={customers}
            onRowClick={(item) => {
              navigate(`/customers/${item._id}/details`);
            }}
            addFunction={() => {
              openModal(lang("Add customer"), <CustomerDetails />);
            }}
            defaultSort={{ column: "_id", direction: "descending" }}
            filterOnTags={true}
            filterOnUser={true}
            columns={[
              {
                name: lang("Customer"),
                uid: "customer",
                sortable: false,
              },
              {
                name: lang("Org number"),
                uid: "organizationNumber",
                sortable: true,
              },
              {
                name: lang("Tags"),
                uid: "tags",
                sortable: false,
              },
              {
                name: lang("Assigned To"),
                uid: "assignedTo",
                sortable: true,
              },
            ]}
            initialVisibleColumns={["customer", "organizationNumber", "tags", "assignedTo"]}
          />
        )}
      </div>
    </div>
  );
}
