import { Board, Contact, Customer, Opportunity } from "feathers-backend";
import { useFormik } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import { client } from "../../../utils/Client";
import { ModalContext } from "../../../contexts/ModalContext";
import { Autocomplete, AutocompleteItem, Avatar, Button, Input, Textarea } from "@nextui-org/react";

import { UserContext } from "../../../contexts/UserContext";
import SelectAssignee from "../Fields/SelectAssignee";
import { DataContext } from "../../../contexts/DataContext";
import { AuthContext } from "../../../contexts/AuthContext";
import SelectTags from "../Fields/SelectTags";

type NewOpportunityProps = {
  board?: Board;
  customer?: Customer;
};

export default function NewOpportunity({ board, customer }: NewOpportunityProps) {
  const { closeModal } = useContext(ModalContext);
  const { customers, contacts, users, boards } = useContext(DataContext);
  const { me } = useContext(AuthContext);

  // Local state for selections
  const [selectedCustomer, setSelectedCustomer] = useState<string | undefined>(customer?._id.toString());
  const [selectedContact, setSelectedContact] = useState<string | undefined>(undefined);
  const [selectedAssignee, setSelectedAssignee] = useState<string | undefined>(me?._id.toString());

  // Adjusted to use local state for sorting
  const [sortedContacts, setSortedContacts] = useState<Contact[]>([]);

  useEffect(() => {
    // Update Formik's state when local selections are confirmed/changed
    formik.setFieldValue("customerId", selectedCustomer);
    formik.setFieldValue("contactId", selectedContact);
    formik.setFieldValue("assignedTo", selectedAssignee);
  }, [selectedCustomer, selectedContact, selectedAssignee]); // Add formik.setFieldValue to dependencies if needed

  const formik = useFormik({
    initialValues: {
      assignedTo: selectedAssignee,
    } as Opportunity,
    onSubmit: async (values) => {
      values.boardId = board?._id.toString() || boards[0]?._id.toString();
      client.service("opportunities").create(values);
      closeModal();
    },
  });

  // Useeffect to fetch a list of contacts in customer contacts array
  useEffect(() => {
    if (selectedCustomer) {
      const selectedCustomerDetails = customers.find((customer) => customer._id.toString() === selectedCustomer);
      if (selectedCustomerDetails?.contacts) {
        const customerContacts = contacts.filter((contact) =>
          selectedCustomerDetails.contacts?.includes(contact._id.toString())
        );
        setSortedContacts(customerContacts);
      } else {
        setSortedContacts([]);
      }
    } else {
      setSortedContacts([]);
    }
  }, [selectedCustomer, customers, contacts]);

  const sortedCustomers = useMemo(() => {
    return [...(customers || [])]
      .filter((customer) => customer && customer.name) // Ensure customer and name exist
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [customers]);

  const { lang } = useContext(UserContext);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-2 gap-2">
        <div className="col-span-2">
          <SelectTags
            name="tags" // The field name in your Formik form
            onChange={formik.handleChange}
            value={formik.values.tags}
          />
        </div>
        <Input
          className="col-span-2"
          label={lang("Display name")}
          name="name"
          type="text"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <div className="col-span-2">
          <Textarea
            label={lang("Notes")}
            name="notes"
            type="text"
            value={formik.values.notes}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <Input
          label={lang("Expected value")}
          name="expectedValue"
          type="number"
          value={formik.values.expectedValue?.toString() || ""}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Autocomplete
          defaultItems={sortedCustomers}
          label="Existing customer?"
          fullWidth={true}
          placeholder="Select a customer"
          labelPlacement="inside"
          isClearable={true}
          selectedKey={formik.values.customerId?.toString()}
          onSelectionChange={(value) => setSelectedCustomer(value as any)}
        >
          {(customer) => (
            <AutocompleteItem key={customer._id.toString()} textValue={customer.name}>
              <div className="flex items-center gap-2">
                <Avatar name={customer.name} className="flex-shrink-0" size="sm" />
                <div className="flex flex-col">
                  <span className="text-small">{customer.name}</span>
                </div>
              </div>
            </AutocompleteItem>
          )}
        </Autocomplete>
        {sortedContacts.length > 0 && (
          <Autocomplete
            defaultItems={sortedContacts}
            label="Existing contact?"
            fullWidth={true}
            placeholder="Select a contact"
            labelPlacement="inside"
            isClearable={true}
            shouldCloseOnBlur={true}
            selectedKey={formik.values.contactId?.toString()}
            onSelectionChange={(value) => setSelectedContact(value as any)}
          >
            {(contact) => (
              <AutocompleteItem key={contact._id.toString()} textValue={contact.firstName + " " + contact.lastName}>
                <div className="flex items-center gap-2">
                  <Avatar name={contact.firstName + " " + contact.lastName} className="flex-shrink-0" size="sm" />
                  <div className="flex flex-col">
                    <span className="text-small">{contact.firstName + " " + contact.lastName}</span>
                  </div>
                </div>
              </AutocompleteItem>
            )}
          </Autocomplete>
        )}
        <div className="col-span-2">
          <Autocomplete
            defaultItems={users}
            label={lang("Assigned to")}
            fullWidth={true}
            placeholder={lang("Select a user")}
            labelPlacement="inside"
            isClearable={true}
            shouldCloseOnBlur={true}
            selectedKey={formik.values.assignedTo?.toString()}
            onSelectionChange={(value) => setSelectedAssignee(value as any)}
          >
            {(user) => (
              <AutocompleteItem key={user._id.toString()} textValue={user.fullName}>
                <div className="flex items-center gap-2">
                  <Avatar name={user.fullName} className="flex-shrink-0" size="sm" />
                  <div className="flex flex-col">
                    <span className="text-small">{user.fullName}</span>
                  </div>
                </div>
              </AutocompleteItem>
            )}
          </Autocomplete>
        </div>
      </div>
      <div className="flex justify-end gap-2 mt-4">
        <Button onPress={closeModal} variant="light">
          {lang("Close")}
        </Button>
        <Button type="submit" color="primary">
          {lang("Save")}
        </Button>
      </div>
    </form>
  );
}
