import {
  ClipboardDocumentListIcon,
  CpuChipIcon,
  DocumentArrowDownIcon,
  LinkIcon,
  QuestionMarkCircleIcon,
  TagIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import NavItem from "../../layout/NavItem";
import { AuthContext } from "../../contexts/AuthContext";
import { useContext } from "react";

const navigation = [
  { role: "user", name: "Profile", to: "/settings/profile", icon: UserIcon },
  { role: "user", name: "Team Members", to: "/settings/team", icon: UsersIcon },
  { role: "admin", name: "Custom fields", to: "/settings/customfields", icon: ClipboardDocumentListIcon },
  { role: "admin", name: "Tags", to: "/settings/tags", icon: TagIcon },
  { role: "admin", name: "Integrations", to: "/settings/integrations", icon: CpuChipIcon },
  { role: "admin", name: "Export data", to: "/settings/export", icon: DocumentArrowDownIcon },
  { role: "admin", name: "Links", to: "/settings/links", icon: LinkIcon },
  { role: "user", name: "FAQ", to: "/settings/faq", icon: QuestionMarkCircleIcon },
];

export default function Nav() {
  const { me } = useContext(AuthContext);

  // Filter navigation items based on user's role
  const filteredNavigation = navigation.filter((item) => {
    // If the item's role is 'user', it's always included
    if (item.role === "user") return true;
    // If the item's role is 'admin', include it only if the user is an admin
    if (item.role === "admin") return me.role === "admin";
    // Default to not including the item
    return false;
  });

  return (
    <nav className="space-y-1 list-none" aria-label="Sidebar">
      {filteredNavigation.map((item, i) => (
        <NavItem key={i} item={item} />
      ))}
    </nav>
  );
}
