import Logo from "../assets/Icons";
import NavItem from "./NavItem";

type DesktopNavProps = {
  navigation: any[];
};

const customNavigation = [{ name: "Avtal", to: "/avtal/" }];

export default function DesktopNav({ navigation }: DesktopNavProps) {
  return (
    <div className="hidden border-r bg-nav lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col border-ash">
      <div className="flex flex-col px-6 pb-4 overflow-y-auto grow gap-y-5">
        <div className="flex items-center h-16 mt-2 shrink-0">
          <Logo />
        </div>
        <nav className="flex flex-col flex-1 mt-2">
          <ul className="flex flex-col flex-1 gap-y-7">
            <li>
              <ul className="-mx-2 space-y-1">
                {navigation.map((item, i) => (
                  <NavItem key={i} item={item} />
                ))}
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
