import { createContext, useEffect, useState } from "react";
import { client } from "../utils/Client";
import {
  Board,
  Opportunity,
  Column,
  Team,
  User,
  Prospect,
  Customfield,
  Customer,
  Contact,
  Tag,
} from "feathers-backend";
import { Selection } from "@nextui-org/react";

interface DataContextProps {
  boards: Board[];
  columns: Column[];
  opportunities: Opportunity[];
  prospects: Prospect[];
  teams: Team[];
  users: User[];
  customfields: Customfield[];
  customers: Customer[];
  contacts: Contact[];
  tags: Tag[];
  tagsFilter: Selection;
  setTagsFilter: React.Dispatch<React.SetStateAction<Selection>>;
}

type ServiceName =
  | "boards"
  | "columns"
  | "opportunities"
  | "prospects"
  | "teams"
  | "users"
  | "customfields"
  | "customers"
  | "contacts"
  | "tags";

export const DataContext = createContext({} as DataContextProps);

export function DataProvider(props: any) {
  const [boards, setBoards] = useState<Board[]>([]);
  const [columns, setColumns] = useState<Column[]>([]);
  const [opportunities, setOpportunities] = useState<Opportunity[]>([]);
  const [prospects, setProspects] = useState<Prospect[]>([]);
  const [teams, setTeams] = useState<Team[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [customfields, setCustomfields] = useState<Customfield[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [tagsFilter, setTagsFilter] = useState<Selection>(new Set());

  const serviceConfigs = [
    { name: "boards", state: boards, updater: setBoards },
    { name: "columns", state: columns, updater: setColumns },
    { name: "opportunities", state: opportunities, updater: setOpportunities },
    { name: "prospects", state: prospects, updater: setProspects },
    { name: "teams", state: teams, updater: setTeams },
    { name: "users", state: users, updater: setUsers },
    { name: "customfields", state: customfields, updater: setCustomfields },
    { name: "customers", state: customers, updater: setCustomers },
    { name: "contacts", state: contacts, updater: setContacts },
    { name: "tags", state: tags, updater: setTags },
  ];

  // Abstract the fetch logic
  const fetchDataForService = async (serviceName: ServiceName, updater: React.Dispatch<any>) => {
    try {
      // @ts-ignore
      const data = await client.service(serviceName).find();
      updater(data.data);
    } catch (error) {
      console.log("Error fetching data for service: " + serviceName + " - " + error);
    }
  };

  // Initialize data and set up event listeners
  useEffect(() => {
    serviceConfigs.forEach(({ name, updater }) => {
      fetchDataForService(name as ServiceName, updater);
      const service = client.service(name as ServiceName);
      service.on("created", () => fetchDataForService(name as ServiceName, updater));
      service.on("patched", () => fetchDataForService(name as ServiceName, updater));
      service.on("removed", () => fetchDataForService(name as ServiceName, updater));
      return () => {
        ["created", "patched", "removed"].forEach((event) => {
          service.removeAllListeners(event);
        });
      };
    });
  }, []);

  const value = {
    boards,
    columns,
    opportunities,
    prospects,
    teams,
    users,
    customfields,
    customers,
    contacts,
    tags,
    tagsFilter,
    setTagsFilter,
  } as DataContextProps;

  return <DataContext.Provider value={value}>{props.children}</DataContext.Provider>;
}
