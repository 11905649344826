import { Input, Textarea } from "@nextui-org/react";
import { Customer } from "feathers-backend";
import React from "react";

type CustomerFormProps = {
  values: Customer;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: any) => void;
};

export default function CustomerForm({ values, handleChange, handleBlur }: CustomerFormProps) {
  return (
    <div>
      <div className="grid grid-cols-2 gap-2">
        <Input
          label="Name"
          name="customer.name"
          type="text"
          value={values.name ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label="Organization number"
          name="customer.organizationNumber"
          type="text"
          value={values.organizationNumber ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label="Custom ID"
          name="customer.customCustomerId"
          type="text"
          value={values.customCustomerId ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label="Website"
          name="customer.website"
          type="text"
          value={values.website ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <div className="col-span-2 mt-2">
          <Textarea
            label="Notes"
            name="customer.notes"
            type="text"
            value={values.notes ?? ""}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="col-span-2 mt-2 text-sm">Billing Address</div>
        <Input
          label="Street"
          name="customer.billingAddress.street"
          type="text"
          value={values.billingAddress?.street ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label="City"
          name="customer.billingAddress.city"
          type="text"
          value={values.billingAddress?.city ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label="Postal code"
          name="customer.billingAddress.postalCode"
          type="text"
          value={values.billingAddress?.postalCode ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label="Country"
          name="customer.billingAddress.country"
          type="text"
          value={values.billingAddress?.country ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <div className="col-span-2 mt-2 text-sm">Visiting Address</div>
        <Input
          label="Street"
          name="customer.visitingAddress.street"
          type="text"
          value={values.visitingAddress?.street ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label="City"
          name="customer.visitingAddress.city"
          type="text"
          value={values.visitingAddress?.city ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label="Postal code"
          name="customer.visitingAddress.postalCode"
          type="text"
          value={values.visitingAddress?.postalCode ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label="Country"
          name="customer.visitingAddress.country"
          type="text"
          value={values.visitingAddress?.country ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
    </div>
  );
}
