import { BriefcaseIcon, ClipboardDocumentListIcon, HandThumbUpIcon, UsersIcon } from "@heroicons/react/24/outline";
import NavItem from "../../layout/NavItem";

const navigation = (customerId: string) => [
  { name: "Details", to: `/customers/${customerId}/details`, icon: BriefcaseIcon },
  { name: "Contacts", to: `/customers/${customerId}/contacts`, icon: UsersIcon },
  { name: "Deals & Opportunities", to: `/customers/${customerId}/deals`, icon: HandThumbUpIcon },
];

type CustomerNavProps = {
  customerId: string;
};

export default function CustomerNav({ customerId }: CustomerNavProps) {
  return (
    <nav className="space-y-1 list-none " aria-label="Sidebar">
      {navigation(customerId).map((item, i) => (
        <NavItem key={i} item={item} />
      ))}
    </nav>
  );
}
