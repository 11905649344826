import { createContext, useState } from "react";
import en from "./lang/en.json";
import sv from "./lang/sv.json";

export const dictionaryList = { en, sv } as any;

export const languageOptions = {
  en: "English",
  sv: "Swedish",
};

export const UserContext = createContext({
  lang: (key: string) => "",
});

type UserSettings = {
  language: string;
  theme: string;
};

export function UserProvider(props: any) {
  const [settings, setSettings] = useState<UserSettings>({
    language: "sv", // default language
    theme: "light", // default theme
  });

  const lang = (key: string) => {
    const translation = dictionaryList[settings.language][key];
    if (!translation) {
      return key;
    } else {
      //console.log("translation not found for key: ", key, " in language: ", settings.language);
      return translation;
    }
  };

  const setLanguage = (language: string) => {
    setSettings((prevSettings) => ({ ...prevSettings, language }));
  };

  const toggleTheme = () => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      theme: prevSettings.theme === "light" ? "dark" : "light",
    }));
  };

  const value = { settings, setLanguage, toggleTheme, lang };

  return <UserContext.Provider value={value}>{props.children}</UserContext.Provider>;
}
