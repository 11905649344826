import { Customer } from "feathers-backend";
import { useCustomer } from "./CustomerShow";
import { client } from "../../utils/Client";
import { useFormik } from "formik";
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Textarea,
} from "@nextui-org/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useContext, useEffect } from "react";
import { UserContext } from "../../contexts/UserContext";
import SelectAssignee from "../../components/Forms/Fields/SelectAssignee";
import { ModalContext } from "../../contexts/ModalContext";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { DataContext } from "../../contexts/DataContext";

export default function CustomerDetails() {
  const { customer } = useCustomer() || ({} as Customer);
  const { closeModal } = useContext(ModalContext);
  const { lang } = useContext(UserContext);
  const { customers } = useContext(DataContext);
  const { me } = useContext(AuthContext);
  const navigate = useNavigate();

  // Useeffect on customers and if this specific customer is updated update the formik values but only if this customer has been updated
  useEffect(() => {
    if (customers && customer) {
      const updatedCustomer = customers.find((c) => c._id === customer._id);
      if (updatedCustomer) {
        formik.setValues(updatedCustomer);
      }
    }
  }, [customers]);

  const deleteCustomer = async () => {
    if (customer) {
      await client.service("customers").remove(customer?._id.toString());

      // Delete the customer contacts based on the Id´s from customer.contacts
      customer.contacts?.forEach(async (contactId) => {
        await client.service("contacts").remove(contactId.toString());
      });

      navigate("/customers");
    }
  };

  const formik = useFormik({
    initialValues: {
      name: customer?.name || "",
      organizationNumber: customer?.organizationNumber || "",
      customCustomerId: customer?.customCustomerId || "",
      website: customer?.website || "",
      notes: customer?.notes || "",
      billingAddress: {
        street: customer?.billingAddress?.street || "",
        city: customer?.billingAddress?.city || "",
        postalCode: customer?.billingAddress?.postalCode || "",
        country: customer?.billingAddress?.country || "",
      },
      visitingAddress: {
        street: customer?.visitingAddress?.street || "",
        city: customer?.visitingAddress?.city || "",
        postalCode: customer?.visitingAddress?.postalCode || "",
        country: customer?.visitingAddress?.country || "",
      },
      assignedTo: customer?.assignedTo || undefined,
    } as Customer,
    onSubmit: async (values: any) => {
      // Remove assignedTo if empty
      if (!values.assignedTo) {
        delete values.assignedTo;
      }

      if (customer) {
        client.service("customers").patch(customer._id.toString(), values);
      } else {
        // Check if there already is a customer with the same organization number from customers
        const existingCustomer = customers.find((c) => c.organizationNumber === values.organizationNumber);

        // Throw an error if a customer with the same organization number already exists
        if (existingCustomer) {
          formik.setErrors({ organizationNumber: "A customer with this organization number already exists." });
          return;
        }

        client.service("customers").create(values);
        closeModal();
      }
    },
  });

  return (
    <div className="">
      <form onSubmit={formik.handleSubmit}>
        <div className="col-span-2 mb-2">Details</div>
        <div className="grid grid-cols-2 gap-2">
          <Input
            label="Name"
            name="name"
            type="text"
            value={formik.values.name ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label="Organization number"
            name="organizationNumber"
            type="text"
            value={formik.values.organizationNumber ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label="Custom ID"
            name="customCustomerId"
            type="text"
            value={formik.values.customCustomerId ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label="Website"
            name="website"
            type="text"
            value={formik.values.website ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="col-span-2 mt-2">
            <Textarea
              label="Notes"
              name="notes"
              type="text"
              value={formik.values.notes ?? ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="col-span-2 mt-2">Billing Address</div>
          <Input
            label="Street"
            name="billingAddress.street"
            type="text"
            value={formik.values.billingAddress?.street ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label="City"
            name="billingAddress.city"
            type="text"
            value={formik.values.billingAddress?.city ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label="Postal code"
            name="billingAddress.postalCode"
            type="text"
            value={formik.values.billingAddress?.postalCode ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label="Country"
            name="billingAddress.country"
            type="text"
            value={formik.values.billingAddress?.country ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="col-span-2 mt-2">Visiting Address</div>
          <Input
            label="Street"
            name="visitingAddress.street"
            type="text"
            value={formik.values.visitingAddress?.street ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label="City"
            name="visitingAddress.city"
            type="text"
            value={formik.values.visitingAddress?.city ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label="Postal code"
            name="visitingAddress.postalCode"
            type="text"
            value={formik.values.visitingAddress?.postalCode ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label="Country"
            name="visitingAddress.country"
            type="text"
            value={formik.values.visitingAddress?.country ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <SelectAssignee
            name="assignedTo"
            onChange={(name, value) => formik.setFieldValue(name, value)}
            value={formik.values.assignedTo?.toString() || undefined}
          />
        </div>
        <div>
          {formik.errors.organizationNumber && (
            <div className="my-3 text-red-500"> {formik.errors.organizationNumber as any} </div>
          )}
        </div>
        <div className="flex justify-end gap-2 my-4">
          <ButtonGroup variant="solid" color="primary">
            <Button type="submit" color="primary">
              {lang("Save")}
            </Button>
            <Dropdown placement="bottom-end">
              <DropdownTrigger>
                <Button className="border-l border-ash " isIconOnly>
                  <ChevronDownIcon className="w-4 h-4" />
                </Button>
              </DropdownTrigger>
              <DropdownMenu aria-label="Customer options" className="max-w-[300px]">
                <DropdownItem onPress={() => formik.submitForm()} key="save" description="Save customer">
                  {lang("Save")}
                </DropdownItem>
                {me &&
                  me.role === "admin" &&
                  ((
                    <DropdownItem onPress={() => deleteCustomer()} key="save" description="Delete customer">
                      {lang("Delete")}
                    </DropdownItem>
                  ) as any)}
              </DropdownMenu>
            </Dropdown>
          </ButtonGroup>
        </div>
      </form>
    </div>
  );
}
